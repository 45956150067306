#testimonials {
  padding-bottom: 0rem;
  width: 100vw;
}

.swiper {
  height: 30rem;
  padding-bottom: 12rem;
  /* this paddint top is to give space for micro interaction on hover of swiper slide */
  padding-top: 2rem;
}

.swiper-slide {
  cursor: grab;
}

.testimonial__client {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 1.5rem;
}

.testimonial__client-avatar {
  width: 2.5rem;
  aspect-ratio: 1/1;
  border-radius: var(--border-radius-2);
  overflow: hidden;
  transition: var(--transition);
}

.swiper-pagination {
  margin-bottom: 8rem;
}

.swiper-pagination-bullet {
  background: var(--color-primary);
}
